:root {
  --pixel-size: 9;
}

body {
  background: #ffffff;
}

.pixelart {
  image-rendering: pixelated;
}

/* Fat merchant no */

.Character_fatmerchantno {
  width: calc(19px * var(--pixel-size));
  height: calc(13px * var(--pixel-size));
  overflow: hidden;
  position: relative;
  margin: 55px auto 0 auto;
}

.Character_spritesheet_fatmerchantno {
  animation: moveSpritesheetfatmerchantno 0.5s steps(4) infinite;
  width: calc(700px * var(--pixel-size));
  position: absolute;
}

.fatmerchantno {
  top: calc(-164px * var(--pixel-size));
  left: calc(-1px * var(--pixel-size));
}

@keyframes moveSpritesheetfatmerchantno {
  from {
    transform: translate3d(0px, 0, 0);
  }
  to {
    transform: translate3d(-15.4%, 0, 0);
  }
}

/* Fat Merchant Selling */

.Character_fatmerchantselling {
  width: calc(19px * var(--pixel-size));
  height: calc(13px * var(--pixel-size));
  overflow: hidden;
  position: relative;
  margin: 55px auto 0 auto;
  /* background: red; */
  top: calc(-5px * var(--pixel-size));
  right: calc(10px * var(--pixel-size));
}

.Character_spritesheet_fatmerchantselling {
  animation: moveSpritesheetfatmerchantselling 3s steps(26) infinite;
  width: calc(700px * var(--pixel-size));
  position: absolute;
}

.fatmerchantselling {
  top: calc(-176px * var(--pixel-size));
  left: calc(-1px * var(--pixel-size));
}

@keyframes moveSpritesheetfatmerchantselling {
  from {
    transform: translate3d(0px, 0, 0);
  }
  to {
    transform: translate3d(-100%, 0, 0);
  }
}
/* Fat Merchant Stall */

.Character_fatmerchantstall {
  width: calc(50px * var(--pixel-size));
  height: calc(35px * var(--pixel-size));
  overflow: hidden;
  position: relative;
  margin: 55px auto 0 auto;
  /* background: green; */
  right: calc(6px * var(--pixel-size));
  margin-bottom: -130px;
}

.Character_spritesheet_fatmerchantstall {
  /* animation: moveSpritesheetfatmerchantselling 0.5s steps(4) infinite; */
  width: calc(200px * var(--pixel-size));
  position: absolute;
}

.fatmerchantstall {
  top: calc(-1px * var(--pixel-size));
  left: calc(-150px * var(--pixel-size));
}

/* Sword Merchant NO*/

.Character_swordmerchantno {
  width: calc(19px * var(--pixel-size));
  height: calc(13px * var(--pixel-size));
  overflow: hidden;
  position: relative;
  margin: 55px auto 0 auto;
  /* background: red; */
  top: calc(-27px * var(--pixel-size));
  right: calc(-25px * var(--pixel-size));
  transform: scaleX(-1);
}

.Character_spritesheet_swordmerchantno {
  animation: moveSpritesheetswordmerchantno 0.7s steps(4) infinite;
  width: calc(700px * var(--pixel-size));
  position: absolute;
}

.swordmerchantno {
  top: calc(-95px * var(--pixel-size));
  left: calc(-1px * var(--pixel-size));
}

@keyframes moveSpritesheetswordmerchantno {
  from {
    transform: translate3d(0px, 0, 0);
  }
  to {
    transform: translate3d(-15.4%, 0, 0);
  }
}

/*Mage Screaming*/

.Character_magescreaming {
  width: calc(19px * var(--pixel-size));
  height: calc(13px * var(--pixel-size));
  overflow: hidden;
  position: relative;
  /* margin: 55px auto 0 auto; */
  /* background: red; */
  margin-bottom: 50px;
  top: calc(4px * var(--pixel-size));
}

.Character_spritesheet_magescreaming {
  animation: moveSpritesheetmagescreaming 5s steps(24) infinite;
  width: calc(700px * var(--pixel-size));
  position: absolute;
}

.magescreaming {
  top: calc(-55px * var(--pixel-size));
  left: calc(-1px * var(--pixel-size));
}

@keyframes moveSpritesheetmagescreaming {
  from {
    transform: translate3d(0px, 0, 0);
  }
  to {
    transform: translate3d(-92.3%, 0, 0);
  }
}
