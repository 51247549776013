:root {
  --pixel-size: 9;
}

body {
  background: #ffffff;
}

.Character_boy {
  width: calc(12px * var(--pixel-size));
  height: calc(14px * var(--pixel-size));
  overflow: hidden;
  position: relative;
  margin: auto;
}

.Character_spritesheet_boy {
  animation: moveSpritesheet 3s steps(16) infinite;
  width: calc(200px * var(--pixel-size));
  position: absolute;
}

.pixelart {
  image-rendering: pixelated;
}
.boypresenting {
  left: calc(-1px * var(--pixel-size));
  top: calc(1px * var(--pixel-size));
}

@keyframes moveSpritesheet {
  from {
    transform: translate3d(0px, 0, 0);
  }
  to {
    transform: translate3d(-100%, 0, 0);
  }
}

/* boy running */

.Character_boyrunning {
  width: calc(12px * var(--pixel-size));
  height: calc(13px * var(--pixel-size));
  overflow: hidden;
  position: relative;
  margin: auto;
}

.Character_spritesheet_boyrunning {
  animation: moveSpritesheetboyrunning 0.5s steps(8) infinite;
  width: calc(200px * var(--pixel-size));
  position: absolute;
}

.boyrunning {
  left: calc(-1px * var(--pixel-size));
  top: calc(-25px * var(--pixel-size));
}

@keyframes moveSpritesheetboyrunning {
  from {
    transform: translate3d(0px, 0, 0);
  }
  to {
    transform: translate3d(-50%, 0, 0);
  }
}

/* boy home */

.Character_boyhome {
  width: calc(36px * var(--pixel-size));
  height: calc(39px * var(--pixel-size));
  overflow: hidden;
  position: relative;
  margin: auto;
  /* background: red; */
}

.Character_spritesheet_boyhome {
  animation: moveSpritesheetboyhome 1s steps(8) infinite;
  width: calc(600px * var(--pixel-size));
  position: absolute;
}

.boyhome {
  left: calc(-3px * var(--pixel-size));
  top: calc(-39px * var(--pixel-size));
}

@keyframes moveSpritesheetboyhome {
  from {
    transform: translate3d(0px, 0, 0);
  }
  to {
    transform: translate3d(-50%, 0, 0);
  }
}
