:root {
  --pixel-size: 9;
}

body {
  background: #ffffff;
}

.Character_oldman {
  width: calc(15px * var(--pixel-size));
  height: calc(14px * var(--pixel-size));
  overflow: hidden;
  position: relative;
  margin: auto;
}

.Character_spritesheet_oldman {
  animation: moveSpritesheetoldman 3s steps(15) infinite;
  width: calc(200px * var(--pixel-size));
  position: absolute;
}

.pixelart {
  image-rendering: pixelated;
}

.oldmanpresenting {
  left: calc(-1px * var(--pixel-size));
  top: calc(-2px * var(--pixel-size));
}

@keyframes moveSpritesheetoldman {
  from {
    transform: translate3d(0px, 0, 0);
  }
  to {
    transform: translate3d(-100%, 0, 0);
  }
}

/* oldman home */

.Character_oldmanhome {
  width: calc(45px * var(--pixel-size));
  height: calc(42px * var(--pixel-size));
  overflow: hidden;
  position: relative;
  margin: auto;
  /* background: red; */
}

.Character_spritesheet_oldmanhome {
  animation: moveSpritesheetoldmanhome 2s steps(6) infinite;
  width: calc(600px * var(--pixel-size));
  position: absolute;
}

.oldmanhome {
  left: calc(-3px * var(--pixel-size));
  top: calc(-48px * var(--pixel-size));
}

@keyframes moveSpritesheetoldmanhome {
  from {
    transform: translate3d(0px, 0, 0);
  }
  to {
    transform: translate3d(-40%, 0, 0);
  }
}

/* old man pointing */

.Character_oldmanpointing {
  width: calc(15px * var(--pixel-size));
  height: calc(11px * var(--pixel-size));
  overflow: hidden;
  position: absolute;
  margin: auto;
  /*  background: red; */
  transform: scaleX(-1);
  left: calc(22px * var(--pixel-size));
  /* top: calc(-30px * var(--pixel-size)); */
}

.Character_spritesheet_oldmanpointing {
  animation: moveSpritesheetoldmanpointing 2s steps(11) infinite;
  width: calc(200px * var(--pixel-size));
  position: absolute;
}

.oldmanpointing {
  left: calc(-1.8px * var(--pixel-size));
  top: calc(-30px * var(--pixel-size));
}

@keyframes moveSpritesheetoldmanpointing {
  from {
    transform: translate3d(0px, 0, 0);
  }
  to {
    transform: translate3d(-73.4%, 0, 0);
  }
}
