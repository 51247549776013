:root {
  --pixel-size: 9;
}

body {
  background: #ffffff;
}

.Character_villagegirl {
  width: calc(13px * var(--pixel-size));
  height: calc(14px * var(--pixel-size));
  overflow: hidden;
  position: relative;
  margin: auto;
}

.Character_spritesheet_villagegirl {
  animation: moveSpritesheetvillagegirl 1.5s steps(8) infinite;
  width: calc(100px * var(--pixel-size));
  position: absolute;
}

.pixelart {
  image-rendering: pixelated;
}

.villagegirlpresenting {
  left: calc(-1px * var(--pixel-size));
  top: calc(-1px * var(--pixel-size));
}

@keyframes moveSpritesheetvillagegirl {
  from {
    transform: translate3d(0px, 0, 0);
  }
  to {
    transform: translate3d(-100%, 0, 0);
  }
}

/* village girl home*/

.Character_villagegirlhome {
  width: calc(39px * var(--pixel-size));
  height: calc(42px * var(--pixel-size));
  overflow: hidden;
  position: relative;
  margin: auto;
  /* background: red; */
}

.Character_spritesheet_villagegirlhome {
  animation: moveSpritesheetvillagegirlhome 1s steps(8) infinite;
  width: calc(300px * var(--pixel-size));
  position: absolute;
}

.villagegirlhome {
  left: calc(-3px * var(--pixel-size));
  top: calc(-42px * var(--pixel-size));
}

@keyframes moveSpritesheetvillagegirlhome {
  from {
    transform: translate3d(0px, 0, 0);
  }
  to {
    transform: translate3d(-100%, 0, 0);
  }
}
